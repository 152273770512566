import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Button from "../../../components/custom-widgets/button";
import { StaticImage } from "gatsby-plugin-image";
import FaqAccordion from "../../../components/faq/faq-accordion";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const ClosingCostOffer = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "closing-cost-hero",
    ...getHeroImgVariables(imgData),
    altText: "Young couple moving in new house, holding keys",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Save on Mortgage Costs"
          }
        },
        {
          id: 2,
          button: {
            id: "closing-cost-offer-hero-cta-1",
            text: "Find a Loan Officer",
            url: "/personal-banking/contact-loan-officer",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Closing Cost Checking Account Offer"
    }
  ];

  const SEOData = {
    title: "Save Big on Closing Costs with a Checking Account",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Save Big on Closing Costs with a Checking Account"
      },
      {
        name: "description",
        content:
          "Find out how you can save up to $600 on closing costs on your mortgage with WaFd Bank's checking account offer for new and existing customers."
      },
      {
        property: "og:title",
        content: "Save Big on Closing Costs with a Checking Account"
      },
      {
        property: "og:description",
        content:
          "Find out how you can save up to $600 on closing costs on your mortgage with WaFd Bank's checking account offer for new and existing customers."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/closing-cost-checking-account-offer"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-closing-costs-03132023.jpg"
      }
    ]
    /* TODO: add SEO Meta Schema creation to FaqAccordion component
    ,
    schema: {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Do I have to open a checking account to get a mortgage from WaFd Bank?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "No, it will never be required to open a checking account in order to apply for a mortgage with WaFd Bank. We are offering up to $600 off closing costs as an incentive to those who choose to open an account and make their payments from their account."
          }
        },
        {
          "@type": "Question",
          name: "Is this offer only available when I'm purchasing a new home?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "No, you can apply this offer towards any new WaFd Bank home loan, including Refinancing, Remodeling, Lot, Construction, and Home Equity Loans."
          }
        },
        {
          "@type": "Question",
          name: "I already have a WaFd Checking account, can I still take advantage of this offer?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, if you already have a WaFd checking account with direct deposit you are eligible to get up to $200 dollars off your mortgage closing costs at the time of loan closing."
          }
        },
        {
          "@type": "Question",
          name: "In order to get up to $400 in additional money off, it says I need to register for EZ-Pay. What is EZ-Pay?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "EZ-Pay is an electronic funds transfer program that collects monthly loan payments directly from your checking account at any bank using your routing and account number."
          }
        },
        {
          "@type": "Question",
          name: "How do I register for EZ-Pay?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Your mortgage broker or loan officer can help you complete the EZ-Pay agreement."
          }
        }
      ]
    }
    */
  };

  const ctaButtonData = {
    id: "open-account-cta",
    type: "link",
    containerClass: "my-3",
    class: `btn-primary w-100 mx-auto w-md-auto`,
    text: "Find a Loan Officer",
    showIcon: false,
    url: "/personal-banking/contact-loan-officer"
  };

  const faqAccordionData = {
    id: "closing-cost-offer-accordion-data",
    title: "Checking Account Mortgage Costs Offer FAQs",
    faqCategoryNames: ["Closing Costs Offer"]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <h1>Existing WaFd Checking Customers can save up to $600 on Mortgage Costs!*</h1>
            <h3 className="text-success font-weight-bolder mb-0">Save $400</h3>
            <p>
              when you set up autopay, pulling your mortgage payment from your checking account at WaFd or any other
              bank.
            </p>
            <h3 className="text-success font-weight-bolder mb-0">Save another $200</h3>
            <p>if you make your mortgage payments from a WaFd Bank checking account (with direct deposit).</p>
            <h3 className="text-success font-weight-bolder mb-0">New to WaFd Bank?</h3>
            <p className="mb-5">
              If you are new to WaFd, talk to your loan officer about taking advantage of saving on your mortgage costs
              for a new mortgage.
            </p>
            <Button {...ctaButtonData} />
          </div>
          <div className="col-md-6 col-lg-6">
            <div style={{ maxHeight: "293px", height: "300px" }}>
              <StaticImage
                src="../../../images/third-party/walt-from-packaging-pdf-touched-up.png"
                style={{ maxHeight: "100%" }}
                imgStyle={{ objectFit: "contain" }}
                alt="WaFd Walt Bank"
              />
            </div>
          </div>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default ClosingCostOffer;
